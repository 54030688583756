// handling radio grid and flex
.radio-custom-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media (max-width: 576px) {
    display: flex !important;
  }
}
.login-button-justify-content {
  justify-content: start;
  @media (max-width: 576px) {
    justify-content: center;
  }
}
// handling children space grid card
.grid-children-space {
  @media (min-width: 400px) {
    & > * {
      max-width: 447.33px;
      min-width: 384px;
      padding-top: 0%;
      //border-radius: 0%;
    }
  }
  margin-inline: -10;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 24px;
  @media (max-width: 1620.313rem) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 576px) {
    display: block !important;
  }
  @media (max-width: 384px) {
    display: block !important;
    max-width: 90vw;
    & > * {
      max-width: 90vw;
    }
  }
}

.grid-dashboard {
  margin-inline: -10;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 24px;
  @media (max-width: 1620.313rem) {
    grid-template-columns: 2fr 1fr;
  }
  @media (max-width: 1216px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.grid-rdv {
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
      margin-bottom: 0.625rem;
      min-width: 100%;
      align-items: center;
    }
    & > :last-child {
      margin-bottom: 0px;
    }
  }
}

// drop down in show view
.card-edit-menu ul > li > svg > path {
  color: #fff;
}
.card-edit-menu ul > li > div {
  color: #fff;
}
// label style
label.custom-label-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: "#001018";
}
label.custom-label-style > span {
  color: #e02d39;
}
// radio inout customization
input[type="radio"] {
  display: none;
}
input[type="radio"] + label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  margin-right: 6px;
  /* background-color only for content */
  background-clip: content-box;
  -webkit-background-clip: content-box;
  border: 2px solid #7c7c7c;
  background-color: #ffffff;
  border-radius: 50%;
}
body[dir="rtl"] {
  input[type="radio"] + label:before {
    content: "";
    margin-left: 6px;
    /* background-color only for content */
  }
}
/* appearance for checked radiobutton */
input[type="radio"]:checked + label:before {
  background-color: #e02d39;
}
/* optional styles, I'm using this for centering radiobuttons */
label {
  display: flex;
  align-items: center;
  justify-content: center;
}
iframe {
  min-width: 100% !important;
}

//table custom style
div[class*="react-bootstrap-table"] {
  table {
    thead {
      background-color: #010101 !important;
    }
    tbody {
      background-color: #f2f2f2 !important;
    }
  }
}

.select-filter {
  display: flex;
  justify-content: start;
  gap: 16px;
  align-items: center;
  & > * {
    min-width: 316.33px;
  }
  & > *[type="button"] {
    min-width: 0px;
    max-height: 40px;
    color: #ffffff !important;
    background-color: #a1a1a1 !important;
    border-color: #a1a1a1 !important;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #fff !important;
      border-color: #757575 !important;
    }
    &:active {
      background-color: #fff !important;
      border-color: #757575 !important;
    }
  }
}

.is-INVALID {
  border-bottom: 0.031rem solid #e02d39;
}

.MuiBreadcrumbs-separator {
  color: white;
  font-size: 20.313rem;
  position: relative;
  top: -2px;
}

div.custom-subLabel-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff; //button dash
}

.table.table-head-custom thead th {
  color: #ffffff !important;
  text-transform: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 1.2rem;
}

div[class*="react-bootstrap-table"] {
  table {
    thead {
      background-color: #040403 !important;
      border-width: 0px 0.031rem 0.031rem 0.031rem;
      border-style: solid;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      border-color: #000000;
      th {
        text-align: center !important;
      }
    }
    tbody {
      background-color: #f4f4f4 !important;

      td {
        text-align: center !important;
      }
    }
  }
}

div[class*="measure-table"] {
  table {
    cursor: pointer;
    flex: 1;
    thead {
      background-color: black !important;
      border-width: 0px 0.031rem 0.031rem 0.031rem;
      border-style: solid;
      border-color: black;
      z-index: 1;
      th {
        text-align: center !important;
      }
    }
    tbody {
      background-color: #ffffff !important;
      border-width: 0px 0.031rem 0.031rem 0.031rem;
      border-style: solid;
      border-color: #7c7c7c;

      td {
        text-align: center !important;
      }
    }
  }
}

.done {
  background-color: #b9f6d3 !important;
}

.notDone {
  background-color: #ffffff !important;
}

.table.table-head-custom thead th {
  font-weight: 400;
  color: #ffffff !important;
  text-transform: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.2rem;
}

.disabled {
  background-color: #d6d6d6 !important;
}

.scrollable {
  max-height: 489px;
}

.responsive-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.outline-button {
  background-color: transparent !important;
  color: #e02d39 !important;
  border-color: #e02d39 !important;
  font-weight: 600;
}

$gaps: (6, 8, 12, 16, 18, 24, 32, 40, 48);
@each $gap in $gaps {
  .gap-#{$gap} {
    gap: #{$gap}px;
    @media (max-width: 576px) {
      gap: 0px;
    }
  }
}

$gaps: (6, 8, 12, 16, 18, 24, 32, 40, 48);
@each $gap in $gaps {
  .gaps-#{$gap} {
    gap: #{$gap}px;
  }
}

.svg-icon.svg-icon-darkBlue svg g [fill] {
  transition: fill 0.3s ease;
  fill: #fff !important; //button pages
}

$flex: 4;
@for $i from 1 through $flex {
  .flex-#{$i} {
    flex: $i;
    @media (max-width: 576px) {
      flex: 0;
    }
  }
}

$fontSize: 24;
@for $i from 1 through $fontSize {
  .fontSize-#{$i} {
    font-size: #{$i}px;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}

.Work-Sans {
  font-family: "Work Sans", sans-serif;
}

.sub-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #303030;
}

// .shadow{
//   -webkit-filter: drop-shadow( 4px 4px 2px rgba(0, 0, 0, 0.5));
//   filter: drop-shadow( 4px 4px 2px rgba(0, 0, 0, 0.5));

// }

.flip-Position {
  margin-top: -290.313rem !important;
}

///download button

.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
  position: relative;
  padding: 8px;
  margin-bottom: 1.25 rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  transition: all 0.15s ease;
}

.button::before,
.button::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 0.313rem);
  border: 0.031rem solid #7d8082;
  transition: all 0.15s ease;
}

.button::before {
  top: 0;
  border-bottom-width: 0;
}

.button::after {
  bottom: 0;
  border-top-width: 0;
}

.button:active,
.button:focus {
  outline: none;
}

.button:active::before,
.button:active::after {
  right: 3px;
  left: 3px;
}

.button:active::before {
  top: 3px;
}

.button:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 0.625rem 1.25 rem;
  color: #fff;
  background-color: #fff;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 0.031rem transparent;
}

.button_lg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #fff;
}

.button_lg::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #fff;
  transition: all 0.2s ease;
}

.button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -0.031rem;
  left: -8px;
  width: 0;
  background-color: #ff4655;
  transform: skew(-15deg);
  transition: all 0.2s ease;
}

.button_text {
  position: relative;
}

.button:hover {
  color: #0f1923;
}

.button:hover .button_sl {
  width: calc(100% + 10.313rem);
}

.button:hover .button_lg::after {
  background-color: #fff;
}

.glow {
  position: relative;
  color: #e02d39;
  font-weight: bold;
  letter-spacing: 0.031rem;
  text-align: center;
  outline: none;
  animation: animate 3s linear infinite;
}

@keyframes animate {
  0%,
  50.1%,
  80%,
  92% {
    color: black;
    box-shadow: none;
    opacity: 0.5;
  }
  18.1%,
  50%,
  80.1%,
  92.1% {
    color: #e02d39;
    opacity: 1;
  }
}

.medical-folder-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 0.2fr);
  gap: 1rem;
  @media (max-width: 80.625rem) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 1rem;
  }
}

.folder-card-header {
  grid-area: 1 / 1 / 2 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}
.growth-table-title {
  grid-area: 2 / 1 / 3 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}
.data-table-growth {
  grid-area: 3 / 1 / 5 / 2;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.growth-table-button {
  grid-area: 5 / 1 / 6 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.mapi-card {
  grid-area: 3 / 2 / 4 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.allergy-card {
  grid-area: 4 / 2 / 5 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.growth-graph-title {
  grid-area: 6 / 1 / 7 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.growth-graph {
  grid-area: 7 / 1 / 8 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.head-circumference-table-title {
  grid-area: 8 / 1 / 9 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}
.data-table-head-circumference {
  grid-area: 9 / 1 / 10 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.head-circumference-table-button {
  grid-area: 10 / 1 / 11 / 3;
  @media (max-width: 80.625rem) {
    grid-area: auto;
  }
}

.modal-dialog.modal-mapi {
  max-width: 80vw !important;
}

.media-icons {
  display: flex;
  gap: 16px; /* Increase the space between icons */
  padding: 0.625rem;
  align-items: center;
  justify-content: flex-start; /* Align icons to the left */
}

/* Adjust icon size */
.media-icons svg {
  width: 1.25 rem; /* Adjust the width of the icons as needed */
  height: auto; /* Maintain aspect ratio */
  transition: color 0.3s; /* Smooth transition for color change */
  color: black;
}

.media-icons svg:hover {
  color: #278ab7; /* Change the color of the icons when hovered */
}

@media (max-width: 576px) {
  .media-icons {
    justify-content: flex-start; /* Align icons to the left */
  }
}

.header {
  background-color: #0a617a;

  color: white;
  width: 100%; /* Full width of the screen */
  margin: 0 auto; /* Center the header horizontally */
  position: fixed; /* Fixed position to stick at the top */
  top: 0; /* Stick to the top */
  z-index: 1000; /* Ensure it's above other content */
}

.footer {
  background-color: #0a617a;
  color: white;
  padding: 0;
  border-radius: 1px;
  width: auto;
  width: 100%;
  bottom: 0;
  width: 100vw;
  position: relative;
}

.footer-container {
  display: flex;
  flex-wrap: wrap; // Allow items to wrap if they exceed container width
}

.footer-section {
  flex: 1; // Distribute remaining space evenly among sections
  padding: 0 0.625rem; // Add some spacing between sections
  max-width: 25%; // Limit maximum width of each section
}

@media (max-width: 768px) {
  .footer-section {
    max-width: 50%; // Change maximum width for smaller screens
  }
}
.cardt {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  color: black;
  border: #0a617a;
}
.doc-card {
  background-color: white !important;
  padding: 1 rem;
  border-radius: 1px;
  color: black;
  width: auto;
  border-color: #0a617a;
  // margin: 0 auto; /* Center the header horizontally */
}
.text-white {
  color: black;
}
.card-button {
  padding: 3 rem; // Adjust padding for consistency
  background-color: white;
  margin: 0.5rem;
  border: 1px solid #0a617a;
  border-radius: 20px;
  color: black;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease; // Add transition for smoother color change

  &:hover {
    background-color: #5fabf1; // Change hover color to match dropdown content
  }
}

.header-a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw; /* Set width to 100vw for full screen */
  height: 100vh; /* Set height to 100vh for full screen */
  background-color: #fff; /* White background */
  position: relative;
  margin-left: auto; /* Center the header horizontally */
  margin-right: auto; /* Center the header horizontally */

  .logo {
    color: #0a617a;
    font-weight: bold;
    margin-right: 1.25rem;
    &:hover {
      color: #5fabf1;
    }
  }
  .header-a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 1px;
    background-color: #ddd;
  }

  /* Styles for nav-list and its children */
  .header-a .nav-list {
    display: flex;
    align-items: center;
  }

  .header-a .nav-list .nav-item {
    display: inline-block;
    margin-right: 1.25rem;
    border-radius: 1px;
  }

  /* Black line at the bottom */
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Span the entire width */
    height: 1px;
    background-color: black;
  }

  .nav-list {
    display: flex;
    align-items: center;

    .nav-item {
      display: inline-block;
      margin-right: 1.25rem; // Adjust spacing between navigation items
    }

    .nav-item.dropdown {
      position: relative; // Set position to relative for dropdown positioning
      margin: 0.313rem;
    }
    .navi-lang {
      display: flex;
      align-items: center;
      margin-right: 1.25rem;
      background-color: white;
      color: black;
      border: 0.35rem solid #084c78;
      &hover {
        background-color: #5fabf1;
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      border-color: #0a617a;

      padding: 0.625rem;
      min-width: 200px; // Set minimum width for dropdown content
      z-index: 1;
      color: white;
      a {
        display: block;
        color: white;
        text-decoration: none;
        padding: 0.35rem;
        background-color: #0a617a;
        border-color: #0a617a;
        transition: background-color 0.3s ease; // Add transition for smoother color change
      }

      a:hover {
        background-color: #5fabf1; // Change hover color to match dropdown content;
        border-radius: 0.625rem;
      }
    }

    .nav-item.dropdown:hover .dropdown-content {
      display: block; // Show dropdown content on hover
    }
    .doc-text {
      color: #0a617a;
      font-size: 0.875rem;
      margin-right: 1.25rem;
      justify-content: center;
    }
    .dropdown-button,
    .documentation-button button,
    .nav-item-button {
      padding: 1 rem; // Adjust padding for consistency
      background-color: white;
      border-color: #0a617a;
      margin: 0.5rem;
      border: 1px solid #1b6d89;
      border-radius: 20px;
      color: #0a617a;
      font-size: 0.875rem;
      cursor: pointer;
      transition: background-color 0.3s ease; // Add transition for smoother color change

      &:hover {
        background-color: #5fabf1; // Change hover color to match dropdown content
      }
    }
    .documentation-button {
      margin-left: 0.313rem; // Adjust margin to match the spacing
    }
    .search-bar {
      display: flex;
      align-items: center;
      border: 0.031rem solid #1b6d89;
      border-radius: 0.625rem;
      height: 40px; // Set the height of the search bar
      margin-left: 1.25 rem; // Add margin-left to create space between search bar and navigation items
      background-color: whitesmoke;
      color: #0a8bd5;
      .search-input {
        flex: 1;
        padding: 8px 1.25 rem; // Match padding with search-button
        background-color: whitesmoke;
        border-radius: 0.625 rem;
        font-size: 0.875rem;
        border: none;
        color: #0a8bd5;
        height: 100%; // Set the height of the search input to match the search bar
        transition: border-color 0.3s ease;

        &:focus {
          outline: none;
        }
      }

      .search-button {
        background-color: whitesmoke;
        border: none;
        padding: 0.313rem; // Match padding with search-input
        color: #0a8bd5;
        font-size: 0.875rem;
        cursor: pointer;
        height: 80%; // Set the height of the search button to match the search bar
        transition: background-color 0.3s ease;
        margin-right: 0.625rem; // Adjust the margin to move the button slightly to the left

        &:hover {
          background-color: lightgray;
        }
      }
    }
  }
}
.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a617a;
  padding: 1.25 rem; // Adjust padding for better spacing
  color: #0a617a;
  border-radius: 0.625rem;
  .calendar-item {
    margin-right: 1.25rem; // Adjust spacing between navigation items
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      padding: 0.625rem; // Add padding for better readability
      text-align: center;
    }

    th {
      background-color: #ded0b6; // Add background color for table header
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #bbab8c; // Alternate row background color
      }

      tr:nth-child(even) {
        background-color: #ded0b6; // Alternate row background color
      }
      .current-day {
        background-color: #a0bcc2; // Define background color for the current day
        font-weight: bold; // Optionally make the text bold for the current day
      }
    }
  }
}
.session-day {
  background-color: red;
}
.button-calendar {
  display: inline-block;
  padding: 8px 16px; // Adjust padding for button
  font-size: 0.875rem; // Reduce font size for button
  background-color: white; // Button background color
  color: #0a617a; // Button text color
  border: 0.5rem solid #0a617a; // Add border to button
  cursor: pointer;
  border-radius: 0.625rem;
  transition: background-color 0.3s ease; // Add transition for hover effect

  &:hover {
    background-color: #5fabf1; // Change background color on hover
  }
}
.langstyle {
  color: #0a617a; /* Set text color to white */
  display: flex;
  align-items: center;
}

.menu-button {
  background-color: white;
  border: 1px solid #0a617a;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  text-decoration: none;
  color: #0a617a; // Adjust color as needed
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: #5fabf1; // Adjust background color on hover
    color: white; // Adjust text color on hover
  }

  .Link-link {
    text-decoration: none;
    color: inherit;
  }

  .SVG-icon {
    margin-right: 0.5rem; // Adjust spacing between icon and text
  }
}
.program-list-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px #0a617a;
  padding: 16px;
  margin-top: 20px;
  background-color: #fff;
}

.program-list-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.program-list-table {
  width: 100%;
  border-collapse: collapse;
}

.program-list-table th,
.program-list-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.program-list-table th {
  background-color: #f2f2f2;
}

.program-list-row:nth-child(even) {
  background-color: #f9f9f9;
}

.no-programs {
  text-align: center;
  padding: 20px;
  color: #999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 700px;
  width: 100%;
  max-height: calc(100% - 20px); /* Adjust to accommodate the close button */
  overflow-y: auto; /* Enable vertical scrollbar */
  position: relative;
}

.modal-close {
  position: absolute;
  top: 3px;
  right: 3px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-inner-content {
  margin-bottom: 20px; /* Add space between inner content and close button */
}
.session-event {
  text-align: center;
  background-color: "#0a617a";
  color: "white";
  padding: "5px";
  border-radius: "5px";
}

.session-details {
  padding: 20px;
}

.session-details h2 {
  margin-top: 0;
}

.session-details button {
  margin-top: 20px;
}
.session-details-title {
  color: #0a617a;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.session-details-form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  color: #0a617a;
  font-weight: bold;
  margin-bottom: 0.25em;
}

.form-control-static {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.home-page {
  padding: 20px;
}

.accordion-button {
  font-weight: bold;
  color: #c0550e; /* Couleur personnalisée */
  border: none; /* Remove default border */
  padding: 10px; /* Padding for the button */
  cursor: pointer; /* Cursor style */
}

.accordion-button:hover {
  color: #0056b3; /* Color on hover */
  background-color: #e2e6ea; /* Background color on hover */
}

.accordion-button:focus {
  box-shadow: none; /* Remove the default focus outline */
}