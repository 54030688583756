//
// Base
//
html {
  height: 100%;
  overflow: hidden;
  --thumbColor: #3f354f;
  -webkit-overflow-scrolling: touch;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#kt_content {
  height: calc(100vh);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
iframe {
  min-width: 100% !important;
}
/*[placeholder]:focus::-webkit-input-placeholder {
  transition: opacity 0.5s 0.5s ease;
  opacity: 0;
}*/
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #d6d6d6;
  border-radius: 5rem;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #3f354f;
  border-radius: 5rem;
}
// Body
body {
  background: $page-bg;
}
// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }
    // // Fixed Content Head
    // .header-fixed.subheader-fixed.subheader-enabled & {
    //   padding-top: get($header-config, desktop, fixed, height) +
    //     get($subheader-config, fixed, height);
    // }
    // Fixed Aside
    // .aside-fixed & {
    //   padding-left: get($aside-config, base, width);
    // }
    // // Minimize Aside
    // .aside-fixed.aside-minimize & {
    //   padding-left: get($aside-config, base, minimized-width);
    // }
  }
  body[dir*="rtl"].aside-fixed .wrapper {
    // padding-right: get($aside-config, base, width);
    // padding-left: 0rem;
  }
  body[dir*="rtl"].aside-fixed.aside-minimize .wrapper {
    // padding-right: get($aside-config, base, minimized-width);
    // padding-left: 0rem;
  }
  // Container
  .container,
  .container-fluid {
    padding: 0;
  }
}
// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }
    // Fixed Subheader
    .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height) +
        get($subheader-config, fixed, height-mobile);
    }
  }
  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}
